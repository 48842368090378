export const RELEASE_HEADER = "Fill the Feedback for the Talent";
export const RELEASE_SUB_HEADING =
  "Please fill your feedback for the resource before releasing";
export const RELEASE_DATE = "Release Date";
export const RELEASE_REASON = "Release Reason";
export const RELEASE_REASON_PLACEHOLDER = "Select a reason";
export const INFORM_EMPLOYEE = "Informed the employee";
export const WITHDRAWAL_REASON = "Withdrawal Reason";
export const WITHDRAWAL_REASON_PLACEHOLDER = "Select a reason for withdrawal";

export const RELEASE_FEEDBACK_ARRAY = [
  {
    value: "Needs Improvement",
    label: "Needs Improvement",
  },
  {
    value: "Meets some of the expectation",
    label: "Meets some of the expectation",
  },
  {
    value: "Consistently meets all expectation",
    label: "Consistently meets all expectation",
  },
  {
    value: "Consistently meets all expectation and exceeds in some",
    label: "Consistently meets all expectation and exceeds in some",
  },
  {
    value: "Consistently exceeds all expectation",
    label: "Consistently exceeds all expectation",
  },
];

export const NINE_BOX_GRID_RATING_ARRAY = [
  {
    value: "Outstanding Performance High Potential",
    label: "Outstanding Performance High Potential",
  },
  {
    value: "Outstanding Performance Moderate Potential",
    label: "Outstanding Performance Moderate Potential",
  },
  {
    value: "Outstanding Performance Limited Potential",
    label: "Outstanding Performance Limited Potential",
  },
  {
    value: "Good Performance High Potential",
    label: "Good Performance High Potential",
  },
  {
    value: "Good Performance Moderate Potential",
    label: "Good Performance Moderate Potential",
  },
  {
    value: "Good Performance Limited Potential",
    label: "Good Performance Limited Potential",
  },
  {
    value: "Poor Performance High Potential",
    label: "Poor Performance High Potential",
  },
  {
    value: "Poor Performance Moderate Potential",
    label: "Poor Performance Moderate Potential",
  },
  {
    value: "Poor Performance High Potential",
    label: "Poor Performance Limited Potential",
  },
];

export const BEHAVIORAL_SKILLS_ARRAY = [
  {
    value: "Interpersonal Skills",
    label: "Interpersonal Skills",
  },
  {
    value: "Team Player Attitude",
    label: "Team Player Attitude",
  },
  {
    value: "Aptitude to Learn",
    label: "Aptitude to Learn",
  },
  {
    value: "Communication Skills",
    label: "Communication Skills",
  },
  {
    value: "On-time Delivery",
    label: "On-time Delivery",
  },
  {
    value: "Initiative and Drive",
    label: "Initiative and Drive",
  },
  {
    value: "Critical thinking",
    label: "Critical thinking",
  },
  {
    value: "Work ethic",
    label: "Work ethic",
  },
];

export const FORM_FIELDS = {
  positiveObservations: "positiveObservations",
  technicalFeedback: "technicalFeedback",
  trainingRecommendations: "trainingRecommendations",
  anyCommitments: "anyCommitments",
  releaseFeedBack: "releaseFeedBack",
  nineBoxGridRating: "nineBoxGridRating",
  behavioralSkill: "behavioralSkill",
  skillMatrixUpdated: "skillMatrixUpdated",
  goalSheetUpdated: "goalSheetUpdated",
};

export const SKILL_MATRIX_UPDATED = "Skill Matrix Updated";
export const GOAL_SHEET_UPDATED = "Goal Sheet Updated";

export const OPTION_YES = "Yes";
export const OPTION_NO = "No";

export const MANAGER_SUMMARY_CONFIRMATION_TOAST_MESSAGE =
  "Your feedback has been submitted successfully";
