export const NO_DATA_RELEASE_PLACEHOLDER =
  "Seems like everyone is actively involved in their individual projects. No talent release requests are listed here.";
export const DUMMY_DATA_RELEASE = [
  {
    employeeId: "INEMP6683",
    employeeName: "Ramkumar Nandeesh Surenahalli",
    employeeEmailId: "abhijeet.dey@emids.com",
    designation: "Senior Analyst - UXP",
    reportingManagerName: "N Madhusudhan",
    primarySkills: "React",
    secondarySkills: ".Net with AddOns",
    employeeRole: null,
    experience: 1,
    projectName: "HUMANA - Digital Health Platform - Ramp Up",
    businessLocation: "INDIA",
    workingLocation: "Bangalore",
    wfmSpoc: "Vasudevan Manavalan",
    releaseRequestOn: "2023-11-20T18:19:48Z",
    plannedReleaseDate: "2023-11-29T18:19:48Z",
    releaseReason: "Project is about to ramp-down",
    talentInformed: true,
  },
  {
    employeeId: "INEMP6683",
    employeeName: "Abhijit Dey",
    employeeEmailId: "abhijeet.dey@emids.com",
    designation: "Senior Analyst - UXP",
    reportingManagerName: "N Madhusudhan",
    primarySkills: "Illustrate/Graphic Design",
    secondarySkills: "",
    employeeRole: null,
    experience: 10,
    projectName: "R1 - Tech Transform&Cloudmed Integration-Track1-Eng",
    businessLocation: "INDIA",
    workingLocation: "Bangalore",
    wfmSpoc: "Vasudevan Manavalan",
    releaseRequestOn: "2023-11-20T18:19:48Z",
    plannedReleaseDate: "2023-11-29T18:19:48Z",
    releaseReason: "Project is about to ramp-down",
    talentInformed: false,
  },
  {
    employeeId: "INEMP6683",
    employeeName: "Abhijit Dey",
    employeeEmailId: "abhijeet.dey@emids.com",
    designation: "Senior Analyst - UXP",
    reportingManagerName: "N Madhusudhan",
    primarySkills: "Illustrate/Graphic Design",
    secondarySkills: "",
    employeeRole: null,
    experience: 14,
    projectName: "UPRISE HEALTH - Australia EAP Engineering - Eng",
    businessLocation: "INDIA",
    workingLocation: "Bangalore",
    wfmSpoc: "Vasudevan Manavalan",
    releaseRequestOn: "2023-11-20T18:19:48Z",
    plannedReleaseDate: "2023-11-29T18:19:48Z",
    releaseReason: "Project is about to ramp-down",
    talentInformed: true,
  },
  {
    employeeId: "INEMP6683",
    employeeName: "Abhijit Dey",
    employeeEmailId: "abhijeet.dey@emids.com",
    designation: "Senior Analyst - UXP",
    reportingManagerName: "",
    primarySkills: "",
    secondarySkills: "",
    employeeRole: null,
    experience: 11,
    projectName: "Titanic",
    businessLocation: "INDIA",
    workingLocation: "",
    wfmSpoc: "Vasudevan Manavalan",
    releaseRequestOn: "2023-11-20T18:19:48Z",
    plannedReleaseDate: "2023-11-29T18:19:48Z",
    releaseReason: "Project is about to ramp-down",
    talentInformed: false,
  },
  {
    employeeId: "INEMP6683",
    employeeName: "Ramkumar Nandeesh Surenahalli",
    employeeEmailId: "abhijeet.dey@emids.com",
    designation: "Senior Analyst - UXP",
    reportingManagerName: "N Madhusudhan",
    primarySkills: "React",
    secondarySkills: ".Net with AddOns",
    employeeRole: null,
    experience: 4,
    projectName: "HUMANA - Digital Health Platform - Ramp Up",
    businessLocation: "INDIA",
    workingLocation: "Bangalore",
    wfmSpoc: "Vasudevan Manavalan",
    releaseRequestOn: "2023-11-20T18:19:48Z",
    plannedReleaseDate: "2023-11-29T18:19:48Z",
    releaseReason: "Project is about to ramp-down",
    talentInformed: true,
  },
  {
    employeeId: "INEMP6683",
    employeeName: "Abhijit Dey",
    employeeEmailId: "abhijeet.dey@emids.com",
    designation: "Senior Analyst - UXP",
    reportingManagerName: "N Madhusudhan",
    primarySkills: "Illustrate/Graphic Design",
    secondarySkills: "",
    employeeRole: null,
    experience: 8,
    projectName: "R1 - Tech Transform&Cloudmed Integration-Track1-Eng",
    businessLocation: "INDIA",
    workingLocation: "Bangalore",
    wfmSpoc: "Vasudevan Manavalan",
    releaseRequestOn: "2023-11-20T18:19:48Z",
    plannedReleaseDate: "2023-11-29T18:19:48Z",
    releaseReason: "Project is about to ramp-down",
    talentInformed: false,
  },
  {
    employeeId: "INEMP6683",
    employeeName: "Abhijit Dey",
    employeeEmailId: "abhijeet.dey@emids.com",
    designation: "Senior Analyst - UXP",
    reportingManagerName: "N Madhusudhan",
    primarySkills: "Illustrate/Graphic Design",
    secondarySkills: "",
    employeeRole: null,
    experience: 2,
    projectName: "UPRISE HEALTH - Australia EAP Engineering - Eng",
    businessLocation: "INDIA",
    workingLocation: "Bangalore",
    wfmSpoc: "Vasudevan Manavalan",
    releaseRequestOn: "2023-11-20T18:19:48Z",
    plannedReleaseDate: "2023-11-29T18:19:48Z",
    releaseReason: "Project is about to ramp-down",
    talentInformed: true,
  },
  {
    employeeId: "INEMP6683",
    employeeName: "Abhijit Dey",
    employeeEmailId: "abhijeet.dey@emids.com",
    designation: "Senior Analyst - UXP",
    reportingManagerName: "",
    primarySkills: "",
    secondarySkills: "",
    employeeRole: null,
    experience: 1,
    projectName: "Titanic",
    businessLocation: "INDIA",
    workingLocation: "",
    wfmSpoc: "Vasudevan Manavalan",
    releaseRequestOn: "2023-11-20T18:19:48Z",
    plannedReleaseDate: "2023-11-29T18:19:48Z",
    releaseReason: "Project is about to ramp-down",
    talentInformed: false,
  },
  {
    employeeId: "INEMP6683",
    employeeName: "Ramkumar Nandeesh Surenahalli",
    employeeEmailId: "abhijeet.dey@emids.com",
    designation: "Senior Analyst - UXP",
    reportingManagerName: "N Madhusudhan",
    primarySkills: "React",
    secondarySkills: ".Net with AddOns",
    employeeRole: null,
    experience: 19,
    projectName: "HUMANA - Digital Health Platform - Ramp Up",
    businessLocation: "INDIA",
    workingLocation: "Bangalore",
    wfmSpoc: "Vasudevan Manavalan",
    releaseRequestOn: "2023-11-20T18:19:48Z",
    plannedReleaseDate: "2023-11-29T18:19:48Z",
    releaseReason: "Project is about to ramp-down",
    talentInformed: true,
  },
  {
    employeeId: "INEMP6683",
    employeeName: "Abhijit Dey",
    employeeEmailId: "abhijeet.dey@emids.com",
    designation: "Senior Analyst - UXP",
    reportingManagerName: "N Madhusudhan",
    primarySkills: "Illustrate/Graphic Design",
    secondarySkills: "",
    employeeRole: null,
    experience: 21,
    projectName: "R1 - Tech Transform&Cloudmed Integration-Track1-Eng",
    businessLocation: "INDIA",
    workingLocation: "Bangalore",
    wfmSpoc: "Vasudevan Manavalan",
    releaseRequestOn: "2023-11-20T18:19:48Z",
    plannedReleaseDate: "2023-11-29T18:19:48Z",
    releaseReason: "Project is about to ramp-down",
    talentInformed: false,
  },
  {
    employeeId: "INEMP6683",
    employeeName: "Abhijit Dey",
    employeeEmailId: "abhijeet.dey@emids.com",
    designation: "Senior Analyst - UXP",
    reportingManagerName: "N Madhusudhan",
    primarySkills: "Illustrate/Graphic Design",
    secondarySkills: "",
    employeeRole: null,
    experience: 11,
    projectName: "UPRISE HEALTH - Australia EAP Engineering - Eng",
    businessLocation: "INDIA",
    workingLocation: "Bangalore",
    wfmSpoc: "Vasudevan Manavalan",
    releaseRequestOn: "2023-11-20T18:19:48Z",
    plannedReleaseDate: "2023-11-29T18:19:48Z",
    releaseReason: "Project is about to ramp-down",
    talentInformed: true,
  },
  {
    employeeId: "INEMP6683",
    employeeName: "Abhijit Dey",
    employeeEmailId: "abhijeet.dey@emids.com",
    designation: "Senior Analyst - UXP",
    reportingManagerName: "",
    primarySkills: "",
    secondarySkills: "",
    employeeRole: null,
    experience: 11,
    projectName: "Titanic",
    businessLocation: "INDIA",
    workingLocation: "",
    wfmSpoc: "Vasudevan Manavalan",
    releaseRequestOn: "2023-11-20T18:19:48Z",
    plannedReleaseDate: "2023-11-29T18:19:48Z",
    releaseReason: "Project is about to ramp-down",
    talentInformed: false,
  },
  {
    employeeId: "INEMP6683",
    employeeName: "Ramkumar Nandeesh Surenahalli",
    employeeEmailId: "abhijeet.dey@emids.com",
    designation: "Senior Analyst - UXP",
    reportingManagerName: "N Madhusudhan",
    primarySkills: "React",
    secondarySkills: ".Net with AddOns",
    employeeRole: null,
    experience: 11,
    projectName: "HUMANA - Digital Health Platform - Ramp Up",
    businessLocation: "INDIA",
    workingLocation: "Bangalore",
    wfmSpoc: "Vasudevan Manavalan",
    releaseRequestOn: "2023-11-20T18:19:48Z",
    plannedReleaseDate: "2023-11-29T18:19:48Z",
    releaseReason: "Project is about to ramp-down",
    talentInformed: true,
  },
  {
    employeeId: "INEMP6683",
    employeeName: "Abhijit Dey",
    employeeEmailId: "abhijeet.dey@emids.com",
    designation: "Senior Analyst - UXP",
    reportingManagerName: "N Madhusudhan",
    primarySkills: "Illustrate/Graphic Design",
    secondarySkills: "",
    employeeRole: null,
    experience: 11,
    projectName: "R1 - Tech Transform&Cloudmed Integration-Track1-Eng",
    businessLocation: "INDIA",
    workingLocation: "Bangalore",
    wfmSpoc: "Vasudevan Manavalan",
    releaseRequestOn: "2023-11-20T18:19:48Z",
    plannedReleaseDate: "2023-11-29T18:19:48Z",
    releaseReason: "Project is about to ramp-down",
    talentInformed: false,
  },
  {
    employeeId: "INEMP6683",
    employeeName: "Abhijit Dey",
    employeeEmailId: "abhijeet.dey@emids.com",
    designation: "Senior Analyst - UXP",
    reportingManagerName: "N Madhusudhan",
    primarySkills: "Illustrate/Graphic Design",
    secondarySkills: "",
    employeeRole: null,
    experience: 11,
    projectName: "UPRISE HEALTH - Australia EAP Engineering - Eng",
    businessLocation: "INDIA",
    workingLocation: "Bangalore",
    wfmSpoc: "Vasudevan Manavalan",
    releaseRequestOn: "2023-11-20T18:19:48Z",
    plannedReleaseDate: "2023-11-29T18:19:48Z",
    releaseReason: "Project is about to ramp-down",
    talentInformed: true,
  },
  {
    employeeId: "INEMP6683",
    employeeName: "Abhijit Dey",
    employeeEmailId: "abhijeet.dey@emids.com",
    designation: "Senior Analyst - UXP",
    reportingManagerName: "",
    primarySkills: "",
    secondarySkills: "",
    employeeRole: null,
    experience: 11,
    projectName: "Titanic",
    businessLocation: "INDIA",
    workingLocation: "",
    wfmSpoc: "Vasudevan Manavalan",
    releaseRequestOn: "2023-11-20T18:19:48Z",
    plannedReleaseDate: "2023-11-29T18:19:48Z",
    releaseReason: "Project is about to ramp-down",
    talentInformed: false,
  },
  {
    employeeId: "INEMP6683",
    employeeName: "Ramkumar Nandeesh Surenahalli",
    employeeEmailId: "abhijeet.dey@emids.com",
    designation: "Senior Analyst - UXP",
    reportingManagerName: "N Madhusudhan",
    primarySkills: "React",
    secondarySkills: ".Net with AddOns",
    employeeRole: null,
    experience: 11,
    projectName: "HUMANA - Digital Health Platform - Ramp Up",
    businessLocation: "INDIA",
    workingLocation: "Bangalore",
    wfmSpoc: "Vasudevan Manavalan",
    releaseRequestOn: "2023-11-20T18:19:48Z",
    plannedReleaseDate: "2023-11-29T18:19:48Z",
    releaseReason: "Project is about to ramp-down",
    talentInformed: true,
  },
  {
    employeeId: "INEMP6683",
    employeeName: "Abhijit Dey",
    employeeEmailId: "abhijeet.dey@emids.com",
    designation: "Senior Analyst - UXP",
    reportingManagerName: "N Madhusudhan",
    primarySkills: "Illustrate/Graphic Design",
    secondarySkills: "",
    employeeRole: null,
    experience: 11,
    projectName: "R1 - Tech Transform&Cloudmed Integration-Track1-Eng",
    businessLocation: "INDIA",
    workingLocation: "Bangalore",
    wfmSpoc: "Vasudevan Manavalan",
    releaseRequestOn: "2023-11-20T18:19:48Z",
    plannedReleaseDate: "2023-11-29T18:19:48Z",
    releaseReason: "Project is about to ramp-down",
    talentInformed: false,
  },
  {
    employeeId: "INEMP6683",
    employeeName: "Abhijit Dey",
    employeeEmailId: "abhijeet.dey@emids.com",
    designation: "Senior Analyst - UXP",
    reportingManagerName: "N Madhusudhan",
    primarySkills: "Illustrate/Graphic Design",
    secondarySkills: "",
    employeeRole: null,
    experience: 11,
    projectName: "UPRISE HEALTH - Australia EAP Engineering - Eng",
    businessLocation: "INDIA",
    workingLocation: "Bangalore",
    wfmSpoc: "Vasudevan Manavalan",
    releaseRequestOn: "2023-11-20T18:19:48Z",
    plannedReleaseDate: "2023-11-29T18:19:48Z",
    releaseReason: "Project is about to ramp-down",
    talentInformed: true,
  },
  {
    employeeId: "INEMP6683",
    employeeName: "Abhijit Dey",
    employeeEmailId: "abhijeet.dey@emids.com",
    designation: "Senior Analyst - UXP",
    reportingManagerName: "",
    primarySkills: "",
    secondarySkills: "",
    employeeRole: null,
    experience: 11,
    projectName: "Titanic",
    businessLocation: "INDIA",
    workingLocation: "",
    wfmSpoc: "Vasudevan Manavalan",
    releaseRequestOn: "2023-11-20T18:19:48Z",
    plannedReleaseDate: "2023-11-29T18:19:48Z",
    releaseReason: "Project is about to ramp-down",
    talentInformed: false,
  },
  {
    employeeId: "INEMP6683",
    employeeName: "Ramkumar Nandeesh Surenahalli",
    employeeEmailId: "abhijeet.dey@emids.com",
    designation: "Senior Analyst - UXP",
    reportingManagerName: "N Madhusudhan",
    primarySkills: "React",
    secondarySkills: ".Net with AddOns",
    employeeRole: null,
    experience: 11,
    projectName: "HUMANA - Digital Health Platform - Ramp Up",
    businessLocation: "INDIA",
    workingLocation: "Bangalore",
    wfmSpoc: "Vasudevan Manavalan",
    releaseRequestOn: "2023-11-20T18:19:48Z",
    plannedReleaseDate: "2023-11-29T18:19:48Z",
    releaseReason: "Project is about to ramp-down",
    talentInformed: true,
  },
  {
    employeeId: "INEMP6683",
    employeeName: "Abhijit Dey",
    employeeEmailId: "abhijeet.dey@emids.com",
    designation: "Senior Analyst - UXP",
    reportingManagerName: "N Madhusudhan",
    primarySkills: "Illustrate/Graphic Design",
    secondarySkills: "",
    employeeRole: null,
    experience: 11,
    projectName: "R1 - Tech Transform&Cloudmed Integration-Track1-Eng",
    businessLocation: "INDIA",
    workingLocation: "Bangalore",
    wfmSpoc: "Vasudevan Manavalan",
    releaseRequestOn: "2023-11-20T18:19:48Z",
    plannedReleaseDate: "2023-11-29T18:19:48Z",
    releaseReason: "Project is about to ramp-down",
    talentInformed: false,
  },
  {
    employeeId: "INEMP6683",
    employeeName: "Abhijit Dey",
    employeeEmailId: "abhijeet.dey@emids.com",
    designation: "Senior Analyst - UXP",
    reportingManagerName: "N Madhusudhan",
    primarySkills: "Illustrate/Graphic Design",
    secondarySkills: "",
    employeeRole: null,
    experience: 11,
    projectName: "UPRISE HEALTH - Australia EAP Engineering - Eng",
    businessLocation: "INDIA",
    workingLocation: "Bangalore",
    wfmSpoc: "Vasudevan Manavalan",
    releaseRequestOn: "2023-11-20T18:19:48Z",
    plannedReleaseDate: "2023-11-29T18:19:48Z",
    releaseReason: "Project is about to ramp-down",
    talentInformed: true,
  },
  {
    employeeId: "INEMP6683",
    employeeName: "Abhijit Dey",
    employeeEmailId: "abhijeet.dey@emids.com",
    designation: "Senior Analyst - UXP",
    reportingManagerName: "",
    primarySkills: "",
    secondarySkills: "",
    employeeRole: null,
    experience: 11,
    projectName: "Titanic",
    businessLocation: "INDIA",
    workingLocation: "",
    wfmSpoc: "Vasudevan Manavalan",
    releaseRequestOn: "2023-11-20T18:19:48Z",
    plannedReleaseDate: "2023-11-29T18:19:48Z",
    releaseReason: "Project is about to ramp-down",
    talentInformed: false,
  },
];
