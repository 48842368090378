export const HEADER='Allocation Request';
export const SUB_HEADING='Please provide the necessary information below to allocate the candidate to the RR';
export const PRIMARY_SKILLS_LABEL='Primary Skills: ';
export const ALLOCATION_START='Allocation Start Date';
export const ALLOCATION_PERCENT='Allocation Percentage';
export const ALLOCATION_PLACEHOLDER='Select allocation percentage';
export const ADD_COMMENT='Add comment from WFM';
export const CHAR_LIMIT='500 Characters';
export const PLACEHOLDER_COMMENT='Write an additional comment for Work Force Management team';
export const REASON_DISAPPROVAL_PLACEHOLDER = 'Select allocation percent';
export const RESOURCE_ALLOCATION_PERCENTAGE = [
    {
        value: 10,
        label: '10%',
    },
    {
        value: 20,
        label: '20%',
    },
    {
        value: 30,
        label: '30%',
    },
    {
        value: 40,
        label: '40%',
    },
    {
        value: 50,
        label: '50%',
    },
    {
        value: 60,
        label: '60%',
    },
    {
        value: 70,
        label: '70%',
    }, 
    {
        value: 80,
        label: '80%',
    },
    {
        value: 90,
        label: '90%',
    },
    {
        value: 100,
        label: '100%',
    },
];