export const HEADER = "Fill the Resource Release Form";
export const SUB_HEADING =
  "Please provide the necessary details to initiate the resource release.";
export const UPDATED = "Updated";
export const RELEASE_DATE = "Release Date";
export const RELEASE_REASON = "Release Reason";
export const RELEASE_REASON_PLACEHOLDER = "Select a reason";
export const INFORM_EMPLOYEE = "Informed the employee";
export const WITHDRAWAL_REASON = "Withdrawal Reason";
export const WITHDRAWAL_REASON_PLACEHOLDER = "Select a reason for withdrawal";
export const MANAGER_COMMENTS = "Additional Comments";
export const MANAGER_COMMENTS_PLACEHOLDER =
  "Please provide additional comments for reason of release";

export const RELEASE_REASONS_ARRAY = [
  {
    value: "Resource is no longer needed in the project",
    label: "Resource is no longer needed in the project",
  },
  {
    value: "Resource is going on maternity or paternity leave",
    label: "Resource is going on maternity or paternity leave",
  },
  {
    value: "Project is about to ramp-down",
    label: "Project is about to ramp-down",
  },
  {
    value: "Resource has been changed his/her domain and technology",
    label: "Resource has been changed his/her domain and technology",
  },
  {
    value: "The project requirements have changed",
    label: "The project requirements have changed",
  },
  {
    value: "Resource has completed his/her assigned tasks",
    label: "Resource has completed his/her assigned tasks",
  },
  {
    value: "Resource is leaving from the organisation",
    label: "Resource is leaving from the organisation",
  },
  {
    value: "Resource is in PIP",
    label: "Resource is in PIP",
  },
];

export const WITHDRAWAL_REASONS_ARRAY = [
  {
    value: "Resource is needed in the project",
    label: "Resource is needed in the project",
  },
  {
    value: "The project requirements have changed,",
    label: "The project requirements have changed,",
  },
  {
    value: "Resource has pending assigned tasks",
    label: "Resource has pending assigned tasks",
  },
];
