export const HEADER_PAGE = "Self Summary";
export const SELF_ASSESSMENT = "Self Assessment";
export const MAX_LENGTH = 300;
export const OPTION_YES = "Yes";
export const OPTION_NO = "No";

export const KEY_ACHIEVEMENT = "Key Professional Achievements";
export const KEY_ACHIEVEMENT_PLACEHOLDER =
  "Write about professional achievements";

export const CURRENT_ACHIEVEMENT = "Major Contributions in Current Project";
export const CURRENT_ACHIEVEMENT_PLACEHOLDER =
  "Write about project contributions";

export const SKILLS_IDENTIFIED = "Top 3 Skilling Needs Identified";
export const SKILLS_IDENTIFIED_PLACEHOLDER = "Write about your 3 top skills";

export const IMPROVEMENT_AREA = "Potential Areas for Improvement";
export const IMPROVEMENT_AREA_PLACEHOLDER = "Write about area of improvements";

export const ASPIRATION = "Aspirations for the Next Role";
export const ASPIRATION_PLACEHOLDER =
  "Write about your aspirations for your next role";

export const TECH_PREFERENCE = "Tech Stack Preference";
export const TECH_PREFERENCE_PLACEHOLDER =
  "Write about your tech stack preferences";

export const GEO_PREFRENCE = "Geography Preference";
export const GEO_PREFRENCE_PLACEHOLDER = "Write about your geography prerence";

export const SHIFT_QUERY = "Willing to Work in Shifts";

export const PRE_RELEASE_HEADING = "Pre-Release Tasks";
export const SKILL_MATRIX = "Skill Matrix Updated?";
export const LATEST_UPDATE = "Latest Updates to Your Profile in BRIDGE?";
export const LONG_LEAVE = "Long Leave/Maternity Leave Plans Informed to WFM?";

export const SELF_SUMMARY_CONFIRMATION_TOAST_MESSAGE =
  "Your self summary has been submitted successfully";
