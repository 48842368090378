export const HEADER = "Review Allocation";
export const SUB_HEADING =
  "Please review the allocation request and adjust the allocation date if necessary.";
export const PRIMARY_SKILLS_LABEL = "Skills:";
export const REQUESTING_MANAGER = "Requesting PM: ";
export const ALLOCATION_DATE = "Allocation Date";
export const ALLOCATION_HEADER = "Allocation (%)";
export const SUGGESTED_RELEASE_DATE = "WFM Suggested Date";
export const WFM_SPOC = "Allot WFM SPOC";
export const ALLOCATE_REASON_PLACEHOLDER = "Select Allocation (%)";
export const WFM_SPOC_PERSONS_PLACEHOLDER = "Select WFM Member";
export const SUGGESTED_BY = "Suggested by ";
export const SUGGESTED_BY_WFM = "Suggested by WFM Team";
export const RAMP_UP_PROJECT = "Allocate Talent to Ramp-up Project";

export const NO_DATA_PLACEHOLDER =
  "It appears that everyone is actively involved in their respective projects, and it seems that no talent allocation requests have been submitted so far.";

  export const UNCONFIRMED_NO_DATA_PLACEHOLDER = "There are no unconfirmed releases at the moment.";