export const ACTION_OPTIONS = [
  {
    label: "View Applicants",
  },
  {
    label: "Add Comments",
  },
  {
    label: "View Comments",
  },
  {
    label: "View History",
  },
];
