export const RELEASE_MESSAGE = "Request send to WFM team successfully";
export const WITHDRAWAL_MESSAGE =
  "Resource release request withdraw successfully";

export const ACTIVE_OPTIONS = [
  {
    label: "Resource Release",
  },
  {
    label: "Release Notes",
  },
  {
    label: "View Reject Comments",
  },
];

export const RELEASE_REQUESTED_OPTIONS = [
  {
    label: "Edit",
  },
  {
    label: "Withdraw",
  },
  {
    label: "Release Notes"
  },
  {
    label: "View Reject Comments",
  },
];

export const RELEASED_OPTION = [
  { 
    label: "Withdraw" 
  }, 
  {
  label: "View Reject Comments",
  },
];
