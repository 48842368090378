export const HEADER = "Review Talent Release";
export const HEADER_WITHDRAWN = "Review Talent Release Withdraw Request";
export const SUB_HEADING =
  "Please review the talent release request and adjust the release date if necessary.";
export const SUB_HEADING_WITHDRAWN =
  "Please review the talent release withdraw request.";
export const PRIMARY_SKILLS_LABEL = "Skills:";
export const REQUESTING_MANAGER = "Requesting PM: ";
export const PROPOSED_RELEASE_DATE = "Proposed Release Date";
export const RELEASE_REASON = "Release Reasons";
export const WITHDRAW_REASON = "Withdraw Reasons";
export const SUGGESTED_RELEASE_DATE = "WFM Suggested Date";
export const WFM_SPOC = "Allot WFM SPOC";
export const RELEASE_REASON_PLACEHOLDER = "Select a reason";
export const WFM_SPOC_PERSONS_PLACEHOLDER = "Select WFM Member";
export const TALENT_INFORMED = "Talent has informed about his/her releasing";
export const RELEASE_REJECTION_COMMENT = "Comments for Rejections";
export const REJECTION_COMMENT_PLACEHOLDER =
  "Please enter comments for rejection of release";
